import React, { useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    backgroundColor: "#ffff",
    width: "100%",
    maxWidth: 400,
    borderRadius: 14,
    boxShadow: "12px 12px 2px 1px rgba(0, 0, 0, .2);",
    height: "100%",
    margin: "0 auto",
    padding: 32,
    boxSizing: "border-box",
  },
  form: {
    width: "100%",
    display: "grid",
    gridRowGap: 8,
    gridTemplateRows: "repeat(auto, 1fr)",
    gridTemplateColumns: "auto",
    marginBottom: 16,
  },
  input: {
    width: "100%",
    margin: "0 auto",
    border: "1px solid silver",
    borderRadius: 8,
    padding: 4,
  },
  inputContent: {
    width: "100%",
    // height: 150,
    border: "1px solid silver",
    borderRadius: 8,
    paddingBottom: 150,
    margin: "0 auto",
  },
  subheading: {
    textAlign: "left",
  },
  button: {
    padding: 8,
    borderRadius: 8,
    boxSizing: "border-box",
    backgroundColor: "#4fc469",
    color: "#ffff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#267537",
    },
    "&:active": {
      backgroundColor: "#7AD98F",
    },
  },
});

const PostForm = () => {
  // STATES
  const [postObj, setPostObj] = useState({
    score: 0,
  });
  const [error, setError] = useState(false);

  const classes = useStyles();

  const handleChange = (e) => {
    setPostObj({
      ...postObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    let id = uid();
    // Validate post isn't empty
    if (
      postObj.username === "" ||
      postObj.content === "" ||
      postObj.title === ""
    ) {
      setError(true);
      return
    } else if (!postObj.username || !postObj.content || !postObj.title) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    await fetch("https://workers.bjma.workers.dev/posts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...postObj,
        id,
      }),
    }).then(setPostObj({ score: 0 }));

    // Clear input
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  return (
    <div className={classes.container}>
      {error && (
        <span style={{ color: "red", }}>
          Something went wrong when trying to upload your post.
        </span>
      )}
      <form className={classes.form}>
        <div className={classes.subheading}>Username</div>
        <input
          className={classes.input}
          type="text"
          name="username"
          onChange={handleChange}
        />
        <div className={classes.subheading}>Title</div>
        <input
          className={classes.input}
          type="text"
          name="title"
          onChange={handleChange}
        />
        <div className={classes.subheading}>Content</div>
        <input
          className={classes.inputContent}
          type="text"
          name="content"
          onChange={handleChange}
        />
      </form>

      <div className={classes.button} onClick={handleSubmit}>
        POST
      </div>
    </div>
  );
};

export default PostForm;
