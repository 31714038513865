import React from "react";
import { createUseStyles } from "react-jss";

import Post from "./Post";

const useStyles = createUseStyles({
  container: {
    overflowY: "scroll",
    height: "100%",
    maxHeight: 575,
    width: "60%",
    maxWidth: 800,
    margin: "0 auto",
    backgroundColor: "#ffff",
    borderSizing: "border-box",
    borderRadius: 14,
    boxShadow: "12px 12px 2px 1px rgba(0, 0, 0, .2)",
  },
  heading: {
    fontSize: 48,
    textAlign: "center",
  },
  postForm: {
    marginTop: 24,
  },
});

const Feed = ({ posts, refreshKey }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.feed}>
        {posts.map((post, i) => {
          return (
            <Post
              title={post.title}
              content={post.content}
              username={post.username}
              id={post.id}
              score={post.score}
              showBorder={i < posts.length - 1}
              key={refreshKey}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Feed;
