import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  border: {
    borderBottom: "1px solid silver",
    textAlign: "left",
    padding: "12px 14px",
    boxSizing: "border-box",
    margin: "0 auto",
  },
  noBorder: {
    textAlign: "left",
    padding: 12,
    boxSizing: "border-box",
    margin: "0 auto",
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 4,
    alignSelf: "center",
  },
  username: {
    fontStyle: "italic",
    color: "#808080",
    marginBottom: 8,
  },
  score: {
    color: "#339EFF",
    marginTop: 2,
    marginLeft: 8,
  },
  flexBox: {
    display: "flex",
  },
  upvote: {
    marginTop: 10,
    marginRight: 12,
    width: 0,
    height: 0,
    borderLeft: "12.5px solid transparent",
    borderRight: "12.5px solid transparent",
    borderBottom: "25px solid green",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "25px solid #54C94A",
    },
  },
  downvote: {
    marginTop: 10,
    marginRight: 12,
    width: 0,
    height: 0,
    borderLeft: "12.5px solid transparent",
    borderRight: "12.5px solid transparent",
    borderTop: "25px solid #B54141",
    cursor: "pointer",
    "&:hover": {
      borderTop: "25px solid red",
    },
  },
});

const Post = ({ title, content, username, score, id, showBorder }) => {
  const [numVotes, setNumVotes] = useState(0);
  const [totalScore, setTotalScore] = useState(score);

  const upvotePost = async () => {
    await fetch("https://workers.bjma.workers.dev/posts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        title,
        content,
        username,
        score: totalScore + 1,
      }),
    });
    setNumVotes(numVotes + 1);
  };

  const downvotePost = async () => {
    await fetch("https://workers.bjma.workers.dev/posts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        title,
        content,
        username,
        score: totalScore - 1,
      }),
    });
    setNumVotes(numVotes - 1);
  };

  useEffect(() => {
    setTotalScore(score + numVotes);
  });

  const classes = useStyles();
  if (showBorder) {
    return (
      <div className={classes.border}>
        <div className={classes.flexBox}>
          <div>
            <div className={classes.upvote} onClick={upvotePost} />
            <div className={classes.downvote} onClick={downvotePost} />
          </div>
          <div>
            <div className={classes.flexBox}>
              <div className={classes.title}>{title}</div>
              {totalScore >= 0 && (
                <div className={classes.score}>+{totalScore}</div>
              )}
              {totalScore < 0 && (
                <div className={classes.score}>{totalScore}</div>
              )}
            </div>
            <div className={classes.username}>@{username}</div>
            <div className={classes.content}>{content}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.noBorder}>
      <div className={classes.flexBox}>
        <div>
          <div className={classes.upvote} onClick={upvotePost} />
          <div className={classes.downvote} onClick={downvotePost} />
        </div>
        <div>
          <div className={classes.flexBox}>
            <div className={classes.title}>{title}</div>
            {totalScore >= 0 && (
              <div className={classes.score}>+{totalScore}</div>
            )}
            {totalScore < 0 && (
              <div className={classes.score}>{totalScore}</div>
            )}
          </div>
          <div className={classes.username}>@{username}</div>
          <div className={classes.content}>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Post;
