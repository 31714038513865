import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import PostForm from "./PostForm";
import Feed from "./Feed";

const useStyles = createUseStyles({
  container: {
    backgroundColor: "#fa7a70",
    height: "100vh",
  },
  heading: {
    fontSize: 48,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      color: "orange",
    },
  },
  toolTip: {
    "&:hover $toolTipText": {
      visibility: "visible",
    },
    paddingTop: 32,
    marginBottom: 56,
  },
  homePage: {
    margin: "0 auto",
    marginTop: 24,
    maxWidth: 1250,
    display: "flex",
    justifyContent: "space-evenly",
    gridColumnGap: 24,
  },
  loading: {
    height: 575,
    width: "60%",
    maxWidth: 800,
    borderSizing: "border-box",
    borderRadius: 14,
    boxShadow: "12px 12px 2px 1px rgba(0, 0, 0, .2)",
    backgroundColor: "#ffff",
    margin: "0 auto",
  },
  toolTipText: {
    marginTop: 4,
    visibility: "hidden",
    backgroundColor: "black",
    width: 200,
    color: '#ffff',
    padding: "5px 0px",
    fontSize: 14,
    margin: "0 auto",
  },
});

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [refreshStateKey, setRefreshStateKey] = useState(0);
  const classes = useStyles();

  const refreshPosts = async () => {
    await fetch("https://workers.bjma.workers.dev/posts")
      .then((res) => res.json())
      .then((data) => {
        // Parse each post from string to JSON
        setPosts(
          data.map((d) => JSON.parse(d)).sort((a, b) => b.score - a.score)
        );
      });
    setRefreshStateKey(refreshStateKey + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetch("https://workers.bjma.workers.dev/posts")
        .then((res) => res.json())
        .then((data) => {
          // Parse each post from string to JSON
          setPosts(
            data.map((d) => JSON.parse(d)).sort((a, b) => b.score - a.score)
          );
          setMounted(true);
        });
    };
    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.toolTip} onClick={refreshPosts}>
        <div className={classes.toolTipText}>Click me to refresh!</div>
        <div className={classes.heading}>TotallyUniqueSocialMediaSite</div>
      </div>
      <div className={classes.homePage}>
        <PostForm />
        {mounted && posts && <Feed posts={posts} key={refreshStateKey} />}
        {!mounted && <div className={classes.loading}></div>}
      </div>
    </div>
  );
};

export default Home;
